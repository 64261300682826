.mobile_delivery_method_wrapper {
  .header {
    padding: 10px 15px;
    background-color: #fff;
    border-top: 1px solid var(--secondary-border-1);
    border-bottom: 1px solid var(--secondary-border-1);
  }

  .body {
    padding: 15px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--secondary-border-1);
    gap: 10px;

    .select {
      padding: 15px;
      background-color: var(--grays-gray-100);
      border-radius: 6px;
      border: 1px solid var(--secondary-border-1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .online-icons {
        display: flex;
        gap: 10px;

        .card {
          padding: 4px 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 24px;
          border: 1px solid var(--secondary-border-1);
          box-shadow: 0px 2px 20px 0px rgba(183, 183, 183, 0.08),
            0px 20px 40px 0px rgba(183, 183, 183, 0.08);
        }

        .more {
          border-radius: 24px;
          border: 1px solid var(--secondary-border-1);
          box-shadow: 0px 2px 20px 0px rgba(183, 183, 183, 0.08),
            0px 20px 40px 0px rgba(183, 183, 183, 0.08);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px 5px;
        }
      }
    }
  }
}

.has_error {
  bottom: 2px solid #dc3545 !important;
}

.placeholder_container {
  padding: 15px;
  background-color: #fff;

  header {
    padding: 20px 0;
  }

  main {
    .main_div_1 {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 15px;
      div {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
    div:last-child {
      background-color: #f8f9fa;
      border-radius: 6px;
    }
  }
}
