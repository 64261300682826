.gift_card_wrapper {
  .title {
    display: flex;
    gap: 10px;
    align-items: center;
    .gift_box {
      padding: 0 7px;
    }
    &_text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .description {
    margin-top: 20px;
  }
  .app_buttons {
    display: flex;
    align-items: center;
    gap: 36px;
    margin-bottom: 30px;
    margin-top: 20.38px;

    svg {
      width: 100%;
    }
  }

  .notify {
    display: flex;
    align-items: center;
    padding: 25px;
    border-radius: 16px;
    background: var(--primary-primary-light);
    gap: 10px;

    margin-bottom: 20px;

    .text {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  .qr_code_container {
    padding: 30px 20px;
    border-radius: 16px;
    border: 1px solid var(--secondary-border-1);
    align-items: center;
    display: flex;
    gap: 10px;
    .text {
      flex: 1;

      p {
        margin: 0;
      }

      :first-child {
        margin-bottom: 10px;
      }

      :nth-child(2) {
        max-width: 307px;
      }
    }
  }
}

@media (max-width: 500px) {
  .gift_card_wrapper {
    margin-top: 15px;
    .title_description_wrapper {
      background-color: #fff;
      border-radius: 16px;
      padding: 15px;
      margin-bottom: 10px;
    }

    .description {
      margin-top: 15px;
      span {
        display: flex;
      }
    }
    .notify_app_buttons_wrapper {
      display: flex;
      flex-direction: column-reverse;
      .app_buttons {
        margin-top: 10px;
        margin-bottom: 38.38px;
        gap: 10px;
      }

      .notify {
        padding: 15px;
        margin-bottom: unset;
      }
    }
  }
}
