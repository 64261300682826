.address_wrapper {
  background-color: #fff;

  .header {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button {
      width: 98px;
      display: flex;
      padding: 0;
      align-items: center;
      height: 22px !important;
    }
  }

  .address {
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    padding: 15px;

    .no_address {
      text-align: center;
    }

    .address_exist {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .address_button {
        background-color: #fafafa;
        border-radius: 4px;
        border: 1px solid #ced4da;
        padding: 8px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .same_address {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
