.header {
  display: flex;
  gap: 10px;
  align-items: center;
  .title {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

@media (max-width: 600px) {
  .header {
    img {
      border-radius: 8px;
    }
  }
}
