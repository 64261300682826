.order_success_wrapper {
  margin-top: 30px;
  .order_detail_section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .sticky_button {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    left: 0;
    border-top: 1px solid var(--Secondary-Border-1, #e2e2e2);
    background: var(--Default-White, #fff);
    box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25);
    a {
      width: 100%;
    }
  }

  .order_success_wrapper__promoted-products__heading {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 20px;
  }
}

@media (max-width: 500px) {
  .order_success_wrapper {
    padding-bottom: 75px;
    padding-top: 15px;

    background-color: #fafafa;
    margin-top: 0;
    .order_detail_section {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }
}

@media (max-width: 1300px) {
  .order_success_wrapper {
    padding: 15px;
  }
}
