.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid var(--secondary-border-1);
  padding: 15px;
  .fast_filters {
    display: flex;
    gap: 6px;
    align-items: center;

    &_title {
      margin-right: 10px;
    }
  }
}

.categories {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  padding: 10px 15px;
  gap: 10px;
  margin-top: 15px;
}

.products {
  margin: 20px 0;
  padding-bottom: 15px;

  .products_container_base {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
  }

  .products_container_extend {
    grid-template-columns: repeat(5, 1fr) !important;
    gap: 25px;
  }
}
