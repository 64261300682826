$font-family: 'Rubik';
$number-of-classes: 8;

.promoted-products__products-item__container {
  display: grid;

  grid-gap: 20px 10px;
  margin-top: 20px;

  @for $i from 1 through $number-of-classes {
    &.itemPerRow-#{$i} {
      grid-template-columns: repeat(#{$i}, 1fr);
    }
  }
}

.productItem {
  position: relative;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  height: 536px;

  .productItem__image {
    width: 100%;
    height: 345px;
    border-radius: 8px 8px 0 0;
    object-fit: cover;
  }

  .link {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.productItem__name {
  padding: 0 10px;
  margin-top: 10px;
  color: #333;
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.productItem__vote {
  margin-top: 5px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.productItem__vote-length {
  color: #999;
  font-family: $font-family;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.productItem__price {
  display: flex;
  align-items: center;
  // margin-top: 29px;
  padding: 0 10px;
  gap: 10px;
}

.productItem__price__sell-price {
  color: #f26b26;
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.productItem__action-buttons {
  margin: 10px 5px;
  padding: 0 5px;
  display: flex;
  gap: 8px;
}

.productItem__action-buttons__add-to-basket {
  padding: 8px 15px;
  border-radius: 6px;
  border: 1px solid #f26b26;
  background: transparent;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  span {
    color: #f26b26;
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &:hover {
    background: #f26b26;

    span {
      color: #fff;
    }
  }
}

.productItem__action-buttons__variations {
  position: relative;
  display: flex;
}

.productItem__action-buttons__variations-button {
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  max-width: 64px;

  span {
    color: #666;
    font-family: $font-family;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  svg {
    margin-top: 1px;
  }

  .productItem__action-buttons__variations-button__icon {
    height: 100%;
    width: 13px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      path {
        fill: #666;
      }
    }
  }
}

.productItem__action-buttons__variations-button-open {
  border: 1px solid #f26b26;
  color: #f26b26;

  span {
    color: #f26b26;
  }

  .productItem__action-buttons__variations-button__icon {
    svg {
      path {
        fill: #f26b26;
      }
    }
  }
}

.productItem__action-buttons__variations-button-selected {
  border: 1px solid #f26b26;
  color: #f26b26;

  span {
    color: #f26b26;
  }

  .productItem__action-buttons__variations-button__icon {
    svg {
      path {
        fill: #f26b26;
      }
    }
  }
}

.productItem__action-buttons__variations-button-error {
  border: 1px solid #f00;

  span {
    color: #f00;
  }

  .productItem__action-buttons__variations-button__icon {
    svg {
      path {
        fill: #f00;
      }
    }
  }
}

.productItem__variations__menu {
  display: flex;
  width: 220px;
  padding: 20px 0;
  flex-direction: column;
  gap: 10px;
  border-radius: 6px;
  border: 0.2px solid rgba(51, 51, 51, 0.1);
  background: var(--White, #fff);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.productItem__variations__menu-item {
  padding: 0 20px;
  height: 26px;
  display: flex;
  align-items: center;

  &:hover {
    background: rgba(217, 217, 217, 0.5);
  }

  span {
    color: #333;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}

@media (max-width: 900px) {
  .promoted-products__products-item__container {
    display: flex;
    margin: 0;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0;

    background-color: white;

    > div {
      background-color: #fafafa;
    }
  }
}

// # productItem Mobile #

.productItem-mobile {
  width: 100%;
  display: flex;
  padding: 15px;
  border-bottom: 1px solid #e2e2e2;
  position: relative;
}

.productItem-mobile__details {
  display: flex;
  position: relative;
}

.productItem-mobile__image {
  width: 80px;
  height: 120px;
  border-radius: 4px;
}

.productItem-mobile__center {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.productItem-mobile__name {
  width: 87%;
  display: block;
}

.productItem-mobile__name-middle {
  display: flex;
  flex-direction: column;
}

.productItem-mobile__price__sub-sell-price {
  color: #f26b26;
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.productItem-mobile__action-buttons__add-to-basket {
  position: absolute;
  right: 15px;
  bottom: 15px;
  display: flex;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  background: transparent;
  outline: none;
  border-radius: 6px;
  border: 1px solid #f26b26;

  span {
    color: #f26b26;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.productItem-mobile__error-message {
  color: #b00;
  font-family: 'Rubik';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-wrap: wrap;
  max-width: 140px;
  margin-bottom: 4px;
}

.overlay {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0.5;
  z-index: 99;
  display: none;
  overflow-x: hidden;
  transition: all 0.5s ease;
}

.overlay-show {
  height: 100vh !important;
  display: block !important;
}

.badges {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 10px;

  .last-x-badge-mobile-wrapper,
  .free-cargo {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
