$border: 1px solid var(--secondary-border-1);

.selected_address {
  border: 1px solid var(--primary-primary) !important;
}

.addedAddress_wrapper {
  border-radius: 6px;
  border: $border;
  padding: 20px;
  flex: 1 0 40%;
  min-height: 150px;
  max-height: 150px;
  cursor: pointer;

  .address {
    margin: 0 !important;
  }

  .header {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 7px;

    .select {
      cursor: pointer;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .title {
      cursor: pointer;
    }

    > div {
      border-radius: 999px;
    }
  }

  .edit-button {
    margin-top: 4px;
    margin-bottom: 0;
    cursor: pointer;
    width: fit-content;
  }
}
