.modal_container {
  padding: 0 !important;
  z-index: 2000;
}

.header {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #e2e2e2;
  > span {
    text-align: center;
    flex: 1;
  }
}

.content {
  background-color: #fafafa;
  padding-bottom: 120px;

  display: flex;
  flex-direction: column;
  gap: 15px;

  .country_container {
    display: flex;
    padding: 15px 15px 0 15px;
    padding-bottom: 0;
    align-items: center;
    gap: 10px;
    .country {
      display: flex;
      align-items: center;
      gap: 4px;
      justify-content: space-between;
      flex: 1;

      .country_name {
        display: flex;
        align-items: center;
        gap: 4px;
        .country_flag {
          width: 10px;
          height: 10px;
          overflow: hidden;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
            max-height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .contact_information {
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;

    background-color: #fff;
    padding: 15px;

    > div {
      margin-bottom: 15px;
    }
  }

  .inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    label {
      margin-bottom: 10px;
    }

    .checkbox {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 0 15px;
      label {
        margin: 0;
      }
    }

    .input {
      display: flex;
      flex-direction: column;
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 15px;
  > label {
    margin: 0;
  }
}

.confirmation {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px;
  border-top: 1px solid var(--secondary-border-1);
  background: #fff;
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.input_required_star {
  padding-left: 3px;
  font-weight: 500;
  color: var(--reds-errors-red-500);
}
