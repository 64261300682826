.container {
  position: relative;

  svg {
    animation: rotate 2s linear infinite;
    z-index: 100;
    position: absolute;
    right: 3px;
    top: 9px;
  }
}

.select {
  padding: 6px 5px;
  outline: none;
  background-color: #fafafa;
  border: 1px solid var(--grays-gray-400);
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  color: #000;
  height: 36px;
}

.hide-icon {
  appearance: none;
}

.error {
  border: 1px solid var(--reds-errors-red-500) !important;
  background-color: #fff1f1 !important;
}

.disabled {
  opacity: 0.2 !important;
  pointer-events: none;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
