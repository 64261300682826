.phoneInput {
  height: 100%;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding-left: 37px !important;
  font-size: 16px;
  padding-top: 1px !important;
}

.phoneInputContainer {
  position: relative;
  display: flex;
  align-items: center;
  height: 58px;

  .codeWrapper {
    height: 100%;
    position: absolute;

    left: 40px;
    z-index: 2;
    display: flex;
    align-items: center;
    .code {
      font-size: 16px;
      margin: 0;
      display: flex;
      line-height: 1;
      margin-top: 1px;
    }
  }
}
.customDropdown {
  position: relative;
  border: 1px solid #ccc;
  border-right: unset;
  height: 100%;
  cursor: pointer;
  background-color: #fafafa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  .imageContainer {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-height: 100%;
    }
  }
}
.hasError {
  border: 1px solid red;
  border-right: unset;
}

.dropdownOptions {
  position: absolute;
  top: 57px;
  display: flex;
  flex-direction: column;
  left: 0;
  width: 100%;
  z-index: 1;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 50px;
  overflow-y: auto;
  -ms-overflow-style: none;
}
.dropdownOptions::-webkit-scrollbar {
  display: none;
}
.dropdownOption {
  padding: 8px;
  display: flex;
  align-items: center;
}
.dropdownOption img {
  margin-right: 8px;
}
.phoneInputContainer input {
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.dropdownOption:hover {
  background-color: #f0f0f0;
}
@media screen and (max-width: 500px) {
  .phoneInput {
    padding-left: 32px !important;
    font-size: 14px !important;
    padding-top: 6.5px !important;
  }
  .phoneInputContainer {
    height: 36px;
  }
  .codeWrapper {
    top: 0 !important;
    .code {
      font-size: 14px !important;
      margin-top: 1 !important;
    }
  }

  .dropdownOptions {
    top: 35px;
  }
}
