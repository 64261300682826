.cart-header__desktop {
  margin-bottom: 10px;
  padding: 10px 0;
}

.cart-header__mobile {
  height: 50px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  position: relative;

  button {
    position: absolute;
    left: 15px;
    background: none;
    border: none;
    outline: none;
  }
}
