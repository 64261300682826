.forget_password_wrapper {
  flex: 1;
  padding: 15px;
  border: 1px solid var(--Secondary-Border-1, #e2e2e2);
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 0px 8px 8px 0px;

  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .input {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .error {
      margin-bottom: 10px;
    }
  }

  .submit_button {
    button {
      height: 58px;
    }
  }
  .mobile_submit_button {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-top: 1px solid var(--secondary-border-1);
    box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
  }
}

@media (max-width: 500px) {
  .forget_password_wrapper {
    border: none;
    padding: 0;
  }
}
