.user_info_wrapper {
  .header {
    padding: 15px;
    border-radius: 8px;
    border: 1px solid var(--secondary-border-1);
    margin-bottom: 20px;
  }
  .cols {
    display: flex;
  }
}
