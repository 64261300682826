.cargo_cost {
  padding: 20px 24px;
  display: flex;
  gap: 20px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: 1px solid var(--secondary-border-1);
  border-bottom: 1px solid var(--secondary-border-1);
  border-left: 1px solid var(--secondary-border-1);
  :first-child {
    flex: 6;
  }

  :last-child {
    flex: 3;
    margin-left: 30px;
  }
}

@media (max-width: 500px) {
  .cargo_cost {
    padding: 10px 0;
    border: none;
    justify-content: space-between;
    gap: unset;

    :first-child {
      flex: unset;
    }

    :last-child {
      flex: unset;
      margin-left: unset;
    }
  }
}
