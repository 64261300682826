.box {
  display: flex;
  padding: 10px 15px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  min-height: 40px;
  align-self: stretch;
  border-bottom: 1px solid var(--secondary-border-1);
  justify-content: center;
  background: var(--default-white);

  input {
    background: transparent;
    border: none;
    outline: none;
    width: 50px;
    height: 20px;
  }
}

.title {
  display: flex;
  gap: 4px;
  align-items: center;

  svg {
    path {
      fill: var(--primary-primary);
    }
  }
}
