.header-desktop {
  display: flex;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--secondary-border-1);
}

.header-mobile {
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 10px 15px;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
  align-self: stretch;
  border-bottom: 1px solid var(--secondary-border-1);
  background: var(--default-white);
}
