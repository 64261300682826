.order_card_wrapper {
  display: flex;
  align-items: center;
  gap: 15px;

  .image {
    img {
      border-radius: 4px;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .information {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .price {
      span {
        white-space: nowrap;
      }
    }
  }
}
