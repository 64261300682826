.mobileSummary-container {
  height: 74px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
  background-color: white;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e2e2e2;
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25);
  &_total {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 10px;

    &_arrowRotate {
      svg {
        rotate: 180deg;
        transition: all 0.3s ease;
      }
    }
    &_arrowRotateBack {
      svg {
        rotate: 0;
        transition: all 0.3s ease;
      }
    }
    &_title {
      color: #333;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 100%;
      margin: 0;
      margin-bottom: 4px;
    }
    &_price {
      color: var(--secondary-secondary-dark, #333);
      font-family: 'Rubik';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 16px */
      margin: 0;
    }
  }
  &_approve {
    flex: 1;
    min-width: 200px;
    > button {
      height: 44px;
      width: 100%;
      border: 1px solid #e26c2d;
      border-radius: 4px;
      color: var(--gray-white, #fff);
      font-family: 'Rubik';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 16px */
    }
  }
}
.mobileSummary-modal-container {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  p {
    margin: 0;
  }
  &_price {
    color: #333;
    text-align: right;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }
  &_price-orange {
    color: #f26b26;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }
  &_title {
    color: #333;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
  &_description {
    color: #333;
    opacity: 0.5;
    font-family: 'Rubik';
    font-size: 10px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 15px */
  }
  &_total {
    display: flex;
    justify-content: space-between;
  }
  &_cargo-price {
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
      color: #333;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    &_details {
      height: 15px;
      display: flex;
      align-items: center;
    }
    &_icon {
      margin-right: 3px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2px;
    }
    &_country {
      color: #333;
      font-family: 'Rubik';
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-right: 7px;
    }
    &_button {
      color: #f26b26;
      font-family: 'Rubik';
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 15px */
      cursor: pointer;
    }
  }
  &_discount {
    display: flex;
    justify-content: space-between;
  }
  &_cargo-discount {
    display: flex;
    justify-content: space-between;
  }
  &_total-wrapper {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    hr {
      width: 100%;
      margin-bottom: 16px;
      margin-top: 0;
    }
  }
  &_total {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.sizeProduct-modal {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 74px;
  left: 0;
  width: 100%;
  margin: 0;
}

.slide-down {
  padding: 15px;
  animation: slide-down 0.4s ease-out forwards;
}

.sizeProduct-modal .modal-dialog {
  margin: 0;
}
.sizeProduct-modal__content {
  border-radius: 0;
  border: none;
  transform: translateY(100%);
  animation: slide-up 0.3s ease-out forwards;
  padding: 15px;
}
@keyframes slide-up {
  to {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  to {
    transform: translateY(100%);
  }
}
