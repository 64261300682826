.agreement_wrapper {
  display: flex;
  padding: 15px;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--secondary-border-1);
  background: #fff;

  .agreement_label {
    cursor: pointer;
  }
}

.agreement_wrapper:has(input) {
  border-top: 1px solid var(--secondary-border-1);
}

.agreement_form {
  padding: 0 15px;
  padding-bottom: 10px;
  background-color: #fff;
  .contract {
    display: flex;
    flex-direction: column;
  }
}
