@mixin variant($bg, $fill) {
  background-color: $bg;
  fill: $fill;
  border: none !important;
  color: $fill;
  padding: 15px 24px;
  border-radius: 8px;
  margin: 0;

  * {
    color: $fill;
  }

  > button {
    opacity: 0;
  }
  > button:hover {
    opacity: 0;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;

  &_icon-text {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &_close-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.danger {
  @include variant(var(--error-red), var(--reds-errors-red-600));
}
.warning {
  @include variant(var(--yellows-warning-yellow-100), var(--yellows-warning-yellow-600));
}
.success {
  @include variant(var(--greens-success-green-100), var(--greens-success-green-600));
}
.info {
  @include variant(var(--blue-links-blue-100), var(--blue-links-blue-600));
}
.raw {
  @include variant(#fff, var(--grays-gray-600));
}
