.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media (max-width: 600px) {
  .container {
    padding: 15px;
    gap: 20px;
  }
}
