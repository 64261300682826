.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

@media (max-width: 576px) {
  .container {
    overflow: auto;
    height: 92dvh;
    padding: 15px;

    .confirm_button {
      min-height: 44px;
    }
  }
}
