.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .uploader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 143px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid var(--secondary-border-1);
    cursor: pointer;
    .icon {
      width: 34px;
      height: 34px;
      background-color: #f8f2ef;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .loading {
      background-color: #cfe2ff;

      svg {
        animation: rotate 2s infinite linear;
        transform-origin: 50% 50%; /* Set rotation center */
      }
    }
  }

  .uploader_disable {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .remove_cursor {
    cursor: auto !important;
  }

  .uploaded_images {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      border-radius: 6px;
      cursor: pointer;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.backdrop_modal {
  z-index: 1055 !important;
  opacity: 0.8 !important;
}

.dialog_modal {
  max-width: 270px !important;
}

.content_modal {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  .icon {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    cursor: pointer;
  }
  img {
    border-radius: 6px;
  }

  .button {
    margin-top: 10px;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid var(--Secondary-Secondary-Light, #abb5be);
    color: var(--Secondary-Secondary-Light, #abb5be) !important;
  }
}

@media (max-width: 576px) {
  .backdrop_modal {
    margin-top: 50px;
  }
  .container_modal {
    margin-top: 50px;
  }
  .dialog_modal {
    max-width: 100% !important;
  }

  .content_modal {
    max-width: 270px;
    margin: 0 auto;
  }
}
