.wrapper {
  padding: 20px;

  hr {
    margin: 10px 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    align-items: center;
    .close {
      cursor: pointer;
    }
  }

  .country_container {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin-bottom: 10px;
    gap: 10px;
    .country {
      display: flex;
      align-items: center;
      gap: 4px;
      .country_image {
        width: 19px;
        height: 19px;
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .country_change {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .two_input {
    width: 100%;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }

  .one_input {
    width: 100%;
    margin-bottom: 10px;
  }

  .default {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 20px;

    * {
      cursor: pointer;
    }

    label {
      margin-bottom: 0;
    }
  }

  label {
    margin-bottom: 10px;
  }

  .checkbox_wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    .checkbox {
      padding: 0 23.5px;
      margin-top: 30px;
      gap: 10px;
      display: flex;
    }

    label {
      margin: 0;
      white-space: nowrap;
    }
  }

  .checkbox_invoice_wrapper {
    margin-bottom: 15px;
    .checkbox {
      margin-top: 30px;
      gap: 10px;
      display: flex;
    }

    label {
      margin: 0;
      white-space: nowrap;
    }
  }

  .input {
    flex: 1;

    input::placeholder {
      font-size: 18px;
    }

    .select_box {
      > div {
        background-color: #f8f9fa;
        > div {
          color: #abb5be;
          font-size: 18px;
        }
      }
    }
  }

  .invoice_type_header {
    padding-top: 20px;
    margin-bottom: 10px;
    line-height: 30px;
    margin-top: 10px;
  }

  .submit_button {
    margin-top: 30px;
    &:disabled {
      background-color: var(--secondary-border-1);
    }
  }
}

.modal {
  min-width: 600px;
}

.modal-content {
  border-radius: 6px;
}

.modal-body {
  padding: 0;
}

.input_required_star {
  padding-left: 3px;
  font-weight: 500;
  color: var(--reds-errors-red-500);
}
