.address_wrapper {
  display: flex;
  gap: 10px;
  .address_container {
    border: 1px solid var(--secondary-border-1);
    padding: 15px;
    border-radius: 6px;
    width: 100%;

    .header {
      padding-bottom: 20px;
      border-bottom: 1px solid var(--secondary-border-1);
      display: flex;
      justify-content: space-between;

      .same_address {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .content-one {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 20px;
    }
    .content-two {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 20px;
      > div {
        flex: 1 0 100%;
      }
    }
  }
}
