.store {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .store_name {
    display: flex;
    gap: 3px;
    align-items: center;
  }

  .store_detail {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .store_follow {
    .store_follow_button {
      padding: 6px 12px;
      height: unset;
    }
  }
  .store_review {
    .store_review_button {
      padding: 6px 12px;
      height: unset;
    }
  }
}

@media (max-width: 570px) {
  .horizontal_line {
    width: 100%;
    height: 1px;
    background: #e2e2e2;
  }
}
