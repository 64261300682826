.orderSummary {
  background: #fff;
  padding: 15px;
  margin: 20px 0;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
}

.payment_agreement_error {
  border: 1px solid var(--reds-errors-red-500) !important;
}

.payment_agreement {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  border-radius: 6px;
  padding: 15px;
  border: 1px solid var(--secondary-border-1);
  label {
    width: 70%;
    cursor: pointer;
    line-height: normal;
  }
  .important {
    text-decoration: underline;
  }
}

.orderSummary__title {
  color: #333;
  font-family: 'Rubik';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 20px;
}

.orderSummary__items {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.orderSummary__item {
  display: flex;
  justify-content: space-between;

  .negative_value {
    color: var(--primary-primary) !important;
  }
}

.orderSummary__Item_cargo-price {
  height: 15px;
  display: flex;
  align-items: center;
  &_icon {
    margin-right: 3px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
  }
  &_country {
    color: #333;
    font-family: 'Rubik';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-right: 7px;
  }
  &_button {
    color: #f26b26;
    font-family: 'Rubik';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 15px */
    cursor: pointer;
  }
}

.orderSummary__separator {
  margin: 0;
  border-color: #e2e2e2;
  opacity: 1;
}

.orderSummary__Item--wrapper {
  display: flex;
  flex-direction: column;
}

.orderSummary__Item--title {
  color: #333;
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.orderSummary__Item--note {
  color: var(--secondary-secondary-dark, #333);
  font-family: 'Rubik';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
}

.orderSummary__Item--price {
  color: #333;
  text-align: right;
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.completeOrder {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.completeOrder__totalValue {
  color: #f26b26;
  text-align: right;
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.completeOrder__button {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: none;
  outline: none;
  background: #f26b26;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  span {
    color: #fff;
    font-family: 'Rubik';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }

  &:disabled {
    background: #e2e2e2;
    border: none;
  }

  &:hover {
    background: #f26a26ee;
  }
}

.orderSummary__protection {
  border-radius: 6px;
  background: #effbf5;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.orderSummary__protection__title {
  display: flex;
  gap: 8px;

  span {
    color: #333;
    text-align: right;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
}

.orderSummary__protection__message {
  margin: 0;
  padding: 0;
  color: #333;
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
