.container {
  padding: 0 6px;
  display: flex;
  flex-direction: column;

  min-width: 42px;
  border-radius: 4px;
  border: 0.5px solid #666;
  text-align: center;
  :first-child {
    border-bottom: 0.5px solid #666;
  }

  :last-child {
    border-top: 0.5px solid transparent;
  }
}

.selected {
  background-color: var(--primary-primary);
  border: 0.5px solid var(--primary-primary);

  :first-child {
    border-bottom: 0.5px solid #fff;
  }

  span {
    color: #fff;
  }
}

.out-of-stock {
  border: 0.5px solid #abb5be;
  position: relative;
  overflow: hidden;

  :first-child {
    border-bottom: 0.5px solid #abb5be;
  }

  span {
    color: #abb5be;
  }

  .out-of-stock-indicator {
    position: absolute;
    top: 18px;
    left: -7px;
    width: 54px;
    height: 1px;
    background-color: #abb5be;
    transform: rotate(-45deg);
  }
}

@media (max-width: 406px) {
  .container {
    min-width: 42px;
  }
}
