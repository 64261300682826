.otp-modal {
  z-index: 3000;
}

.modal {
  min-width: 602px;
}

.modal-content {
  border-radius: 16px;
}

.wrapper {
  padding: 50px;

  .header {
    margin-bottom: 24px;
    > h2 {
      padding: 10px 20px;
      margin: 0;
    }
  }
  .subtitle-container {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;

    .subtitle {
      width: 70%;
    }
  }
  .countdown {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    div > div {
      font-weight: bold;
    }
  }

  .box {
    background-color: #fff;
    padding: 24px;

    .inputContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      .inputs {
        width: 100%;
        font-weight: bold;
      }
      .inputCharacter {
        border-radius: 4px;
        background-color: #fafafa;
        border: 1px solid var(--gray-400, #ced4da);
        color: #000 !important;
        z-index: 100;
        width: 67px;
        height: 58px;
      }
      .inputCharacterSelected {
        border: 1px solid #f26b26;
        outline: none;
      }
    }
  }
  .action {
    margin-top: 24px;
    gap: 5px;
  }
}

@media (max-width: 500px) {
  .modal {
    min-width: unset;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    width: 387px;
  }

  .wrapper {
    padding: 20px;
    h2 {
      margin-bottom: 14px;
    }
  }

  .subtitle {
    width: 85% !important;
  }

  .box {
    padding: 0 !important;
  }

  .inputs {
    * {
      font-size: 18px;
    }
  }

  .inputCharacter {
    width: 40px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .action {
    margin-top: 5px !important;
  }
}

.alert > div {
  height: auto;
}
