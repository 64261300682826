.products {
  margin-top: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  img {
    border-radius: 4px;
  }

  .product {
    width: 100%;
    display: flex;
    padding: 24px 16px;
    gap: 20px;
    border-radius: 8px;
    border: 1px solid var(--secondary-border-1);

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .top {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .size {
          display: flex;
          gap: 8px;
        }
      }

      .price {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .review_button {
          width: fit-content;
          height: 32px;
          > span {
            gap: 8px;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .products {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    .product {
      padding: 12px;
      .content {
        gap: 4px;
        justify-content: flex-start;
        .price {
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          .review_button {
            width: fit-content;
            height: 22px;
            > span {
              gap: 2px;
            }
          }
        }
      }
    }
  }
}
