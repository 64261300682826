.header {
  background-color: #eaffbc;
  padding: 50px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .information {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .title {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .order_id {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (max-width: 500px) {
  .header {
    flex-direction: column;
    padding: 15px;
    margin-bottom: 15px;

    .information {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .order_id {
      margin-top: 15px;
      align-items: center;
    }
  }
}
