.category {
  padding: 6px 12px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: 1px solid var(--secondary-border-1);
  span {
    white-space: nowrap;
  }
  cursor: pointer;
  &:not(.active):hover {
    border: 1px solid var(--secondary-secondary);
    span {
      color: #333333;
    }
  }
  span {
    color: var(--secondary-secondary-light);
  }
}

.active {
  background-color: var(--primary-primary);

  border: 1px solid transparent;
  span {
    color: #fff;
  }
}
