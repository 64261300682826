.address_card_wrapper {
  border-radius: 6px;
  border: 1px solid var(--secondary-border-1);
  padding: 20px 24px;
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
  }
  .body {
    display: flex;
    flex-direction: column;

    .address {
      margin: 0;
    }
  }
}

@media (max-width: 500px) {
  .address_card_wrapper {
    width: 100%;
    background-color: #fff;
  }
}
