.error {
  margin-bottom: 20px;
  .alert {
    margin-bottom: 20px;
  }
  .error_message {
    padding: 0 15px;
    p {
      margin: 0;
    }
  }
}

@media (max-width: 500px) {
  .error {
    margin-bottom: unset !important;
    .alert {
      margin-bottom: 10px !important;
    }

    .error_message {
      padding: unset !important;
    }
  }
}
