.addresses_placeholder_container {
  padding: 15px;
  header {
    height: 46px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;

    > div {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
  main {
    > div {
      display: flex;
      gap: 10px;

      .add {
        width: 100%;
        flex: 1;
        position: relative;
        padding: 15px;
        .inner_content {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          justify-content: center;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .exist {
        padding: 15px;
        width: 100%;
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .inner_content {
          display: flex;
          width: 100%;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 5px;
        }
      }
    }
  }
}

.mobile_address_container {
  header {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding: 0 15px;
    background-color: #fff;
  }

  main {
    padding: 15px 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .main_div_1 {
      display: flex;
      align-items: center;
      padding: 5px 13px;
      border-radius: 4px;
      background-color: #fafafa;
      gap: 23px;
    }
    .main_div_2 {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
    }
  }
}
