.modal {
  z-index: 2000;
}

.modal-container {
  overflow: hidden;
}

.header {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #e2e2e2;
  > span {
    text-align: center;
    flex: 1;
  }
}

.wrapper {
  background-color: #fafafa;
  position: relative;

  padding-top: 5px;

  .add_address_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 5px;

    .add_address {
      padding: 15px 0;
      border-radius: 6px;
      border: 1px solid var(--secondary-border-1);
      width: 100%;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 54px;
      gap: 10px;
    }
  }

  .address_list {
    padding-inline: 15px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    overflow-y: scroll;
    height: calc(100dvh - 136px);
    padding-bottom: 91px;
  }

  .confirm {
    position: fixed;
    bottom: 0;
    z-index: 3000;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-top: 1px solid var(--secondary-border-1);
    background-color: #fff;
    box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }
}
