.product-quantity-adjustment-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.product-quantity-adjustment {
  display: flex;
  width: 90px;
  height: 34px;
  border-radius: 4.8px;
  border: 1px solid var(--secondary-border-1);
  overflow: hidden;
}

.product-quantity-adjustment__button {
  width: 20px;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-primary);
  padding: 5px;
  &:disabled {
    color: var(--secondary-border-1);
  }
}

.left {
  background: #fafafa;
  border-right: 1px solid var(--secondary-border-1);
}

.right {
  background: #fafafa;
  border-left: 1px solid var(--secondary-border-1);
}

.product-quantity-adjustment__button_icon {
  width: 9px;
  cursor: pointer;
}

.product-quantity-adjustment__quantity {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 900px) {
  .product-quantity-adjustment {
    height: 25px;
  }
}
