.free_cargo {
  padding: 10px 20px;
  width: 100%;
  display: flex;
  gap: 10px;
  border-radius: 8px;
  background-color: rgba(52, 199, 89, 0.2);
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;

  .icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -1px;
  }
}

.not_free {
  background-color: #fef4eb;
}

@media (min-width: 500px) {
  .free_cargo {
    padding: 20px;
  }
}
