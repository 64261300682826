.header {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;

  img {
    border-radius: 4px;
  }

  .info {
    display: flex;
    flex-direction: column;
    .name {
      margin-top: 4px;
    }
    .variants {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 4px;
    }
    .agreement {
      margin-top: 11px;
      .aggrement_action {
        margin-top: 4px;
        display: flex;
        align-items: center;
        gap: 1px;
        > span {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
