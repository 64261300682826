$border: 1px solid var(--secondary-border-1);

.newAddress_wrapper {
  border-radius: 6px;
  border: $border;
  padding: 15px;
  flex: 1 0 40%;
  min-height: 150px;
  display: flex;

  .newAddress {
    background-color: #fafafa;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 13px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
  }
}
