.installment_mobile_wrapper {
  border-bottom: 1px solid var(--secondary-border-1);
  background-color: #fff;
  .header {
    border-top: 1px solid var(--secondary-border-1);
    border-bottom: 1px solid var(--secondary-border-1);
    padding: 15px;
    display: flex;
    align-items: center;
  }
  .body {
    padding: 15px;
    .while_at_door {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .installments {
      .info {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}
